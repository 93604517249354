var render = function render(){var _vm=this,_c=_vm._self._c;return _c('YLayoutBodyMenu',{attrs:{"aside-width":"8rem","menu":[
    {title:'入口链接',path:'/shop/system/entry'},
    {title:'系统公告',path:'/shop/system/sys-notice'},
    {title:'弹窗广告',path:'/shop/system/ad-popup'},
    {title:'站内配置',path: '/shop/system/set',children: [
      {title:'首页轮播',path:'/shop/system/banner'},
      {title:'首页宫格',path:'/shop/system/gird'},
      {title:'邀请海报',path:'/shop/system/poster-v2'},
      {title:'基础规则',path:'/shop/system/rule'},
      {title:'自定菜单',path:'/shop/system/tabbar'},
      {title:'基础配置',path:'/shop/system/info'},
    ]},
    {title: '第三方对接',path: '/shop/system/third',children:[
      {title:'微公众号',path:'/shop/system/wx-official'},
      {title:'借权发佣',path:'/shop/system/wx-official-substitute'},
      {title:'微小程序',path:'/shop/system/wxapp'},
      {title:'汇聚支付',path:'/shop/system/joinpay'},
      {title:'微信支付',path:'/shop/system/wx-pay'},
      {title:'阿里支付',path:'/shop/system/ali-pay'},
      {title:'短信配置',path:'/shop/system/sns'},
      {title:'附件管理',path:'/shop/system/attach'},
    ]},
]}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }